/**
 * @ngdoc service
 * @name accountApiService
 * @module flowingly.runner.services
 *
 * @description A service responsible solely for fetching account data
 *
 * ## Notes
 * No Caching or formatting to be performed here.
 * i.e. we return response.data.DataModel so the consumer can work with the data directly
 *
 */

'use strict';
import { SharedAngular } from '@Client/@types/sharedAngular';
import angular from 'angular';

angular
  .module('flowingly.runner.services')
  .factory('accountApiService', accountApiService);

accountApiService.$inject = ['$http', 'APP_CONFIG', 'sessionService'];

function accountApiService(
  $http: angular.IHttpService,
  APP_CONFIG: SharedAngular.APP_CONFIG,
  sessionService: SharedAngular.SessionService
) {
  const service = {
    userProfile: undefined,
    getUserProfile: getUserProfile,
    updateUserDetails: updateUserDetails,
    updateAvatar: updateAvatar,
    removeAvatar: removeAvatar,
    turnOffDelegation: turnOffDelegation,
    changeUserSetting: changeUserSetting
  };

  return service;

  //////////// Public API Methods

  function getUserProfile() {
    return $http
      .get<IUResponseData>(
        APP_CONFIG.apiBaseUrl +
          'account/userProfile?userId=' +
          sessionService.getUser().id +
          '&clientId=' +
          sessionService.getUser().businessId
      )
      .then(function (response) {
        // kick deleted user out
        if (sessionService.checkIfUserDeleted(response.data)) {
          return undefined;
        }

        service.userProfile = response.data.DataModel;
        return service.userProfile;
      });
  }

  function updateUserDetails(details) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + '/account/UpdateProfile', details)
      .then(function (response) {
        return response;
      });
  }

  function updateAvatar(details) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + '/account/UpdateAvatar', details)
      .then(function (response) {
        return response;
      });
  }

  function removeAvatar(details) {
    return $http
      .post(APP_CONFIG.apiBaseUrl + '/account/RemoveAvatar', details)
      .then(function (response) {
        return response;
      });
  }

  function turnOffDelegation() {
    return $http
      .post(
        APP_CONFIG.apiBaseUrl +
          '/delegation/turnOffDelegation/' +
          sessionService.getUser().id,
        undefined
      )
      .then(function (response) {
        return response;
      });
  }

  function changeUserSetting(
    settingName: string,
    settingValue: string,
    valueIsBoolean = false
  ) {
    return $http
      .put(
        APP_CONFIG.apiBaseUrl +
          'account/settings/' +
          encodeURIComponent(settingName),
        JSON.stringify(settingValue)
      )
      .then((response) => {
        APP_CONFIG.updateSetting(settingName, settingValue, valueIsBoolean);
        return response;
      });
  }
}

export type AccountApiServiceType = ReturnType<typeof accountApiService>;
